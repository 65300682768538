<template>
  <generic-section comp-id="testimonials" bg-color="linear-gradient(#E8EDFF, #FBFCFF)">
    <div style="position: absolute; width: 100%; height: 100%; padding: 0; margin: 0;" class="floating-user-box-container">
      <floating-user-box
          v-for="(item, index) in userReviews"
          :item="item"
          :index="index"
          :selected="index === activeReview"
          :custom-styles="userBoxPositions[index]"
          v-bind:key="index"
          :active="showFloat === index"
          :on-click="changeActiveReview"
          @floating-box-enter="floatingBoxEnter"
          @floating-box-leave="floatingBoxLeave"
      />
    </div>
    <div style="position: relative; z-index: 1; opacity: 0;" id="testimonials-header-container">
      <h1 class="section-header" id="testimonials-header">{{ $t("TESTIMONIALS_HEADER") }}</h1>
      <span class="section-text" id="testimonials-text">{{$t("TESTIMONIALS_CONTENT") }}</span>
    </div>
    <div class="usr-review-container" id="usr-review-container">
      <!--<user-review
          v-if="userReviews.length > 0"
          v-bind="userReviews[activeReview]"
          class="usr-review" />-->
    </div>
    <mobile-review-navigation class="usr-review-navigation" :on-click="changeActiveReview" :size="userReviews.length" :current-item="activeReview" />
  </generic-section>
</template>

<script>
import GenericSection from "@/components/generic/GenericSection";
import { ref, onMounted } from "vue";
// import UserReview from "@/components/custom/UserReview";
import FloatingUserBox from "@/components/web/FloatingUserBox";
import MobileReviewNavigation from "@/components/mobile/TestimonialNavigation";
import constants from "@/utils/constants";
export default {
  name: "Reviews",
  components: {
    "generic-section": GenericSection,
    //"user-review": UserReview,
    "floating-user-box": FloatingUserBox,
    "mobile-review-navigation": MobileReviewNavigation
  },
  emits: ["active-testimonial-change"],
  setup(props, { emit }) {
    const userReviews = ref([]);
    const activeReview = ref(8);
    const floatingBoxTimer = ref(null);
    const showFloat = ref(8);
    function changeActiveReview(index) {
      activeReview.value = index;
      emit('active-testimonial-change', {...userReviews.value[index], ...constants.userBoxPositions[index]})
    }
    fetch("https://static.cdwscan.com/testimonials.json").then(res => res.json()).then(response => {
      userReviews.value = response.testimonials
      emit('active-testimonial-change', {...userReviews.value[activeReview.value], ...constants.userBoxPositions[activeReview.value]})

    })
    function floatingBoxEnter(index) {
      clearInterval(floatingBoxTimer.value)
      floatingBoxTimer.value = null;
      showFloat.value = index;
    }
    function floatingBoxLeave() {
      showFloat.value = Math.floor(Math.random() * userReviews.value.length);
      floatingBoxTimer.value = setInterval(() => {
        showFloat.value = Math.floor(Math.random() * userReviews.value.length);
      }, 4000)
    }
    onMounted(() => {
      floatingBoxTimer.value = setInterval(() => {
        showFloat.value = Math.floor(Math.random() * userReviews.value.length);
      }, 4000)
    })
    return {
      userReviews,
      activeReview,
      changeActiveReview,
      showFloat,
      floatingBoxEnter,
      floatingBoxLeave,
      userBoxPositions: constants.userBoxPositions
    }
  }
}
</script>

<style scoped>
.usr-review-container {

  position: absolute;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -50%) scale(0);
  box-sizing: border-box;
  padding: 50px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 17px 12px 72px rgba(118, 123, 170, 0.12);
  z-index: 9999;
  border-radius: 20px;
  overflow: hidden;
}
.usr-review {
  width: 100%;
  height: 100%;
}
.usr-review-navigation {
  display: none;
  width: 100%;
  height: 0;
}
.section-header {
  margin: calc(75px + 4.5vh) 0;
  margin-bottom: 0.7vh;
  box-sizing: border-box;
  font-size: 5vh;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2e293d;
}
.section-text {
  width: 90%;
  margin-left: 5%;
  box-sizing: border-box;
  margin-top: 0.7vh;
  font-size: 2.2vh;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.8px;
  text-align: center;
  color: #c8c8c8;
}

@media screen and (max-width: 992px) {
  .section-header {
    font-size: min(1.7rem, 2.8vh);
    margin: 10px 0;
  }
  .section-text {
    font-size: 0.9rem;
  }
  .usr-review-container {
    width: 100%;
    padding: 10px 10px;
    height: 60%;
  }
  .usr-review {
    height: 90%;
  }
  .usr-review-navigation {
    display: inherit;
    height: 10%;
  }
  .floating-user-box-container {
    display: none;
  }
}
</style>