<template>
<div class="mobile-navigation-container">
  <a :disabled="currentItem === 0" class="mobile-navigation-button" @click="() => onClick(currentItem - 1)">
    <div class="mobile-navigation-button-back-layer" />
    <svg-icon icon="arrow-backward" />
  </a>
  <a :disabled="currentItem === size - 1" class="mobile-navigation-button" @click="() => onClick(currentItem + 1)">
    <div class="mobile-navigation-button-back-layer" />
    <svg-icon icon="arrow-forward" />
  </a>
</div>
</template>

<script>
import GenericSvgIcon from "@/components/generic/GenericSvgIcon";
export default {
  name: "MobileReviewNavigation",
  components: {
    "svg-icon": GenericSvgIcon
  },
  props: {
    size: {
      type: Number,
      default: 0
    },
    onClick: {
      type: Function,
      default: () => {}
    },
    currentItem: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style scoped>
  .mobile-navigation-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

  }
  .mobile-navigation-button {
    border-radius: 50%;
    background: #FFFFFF;
    margin: 15px 10px;
    padding: 15px;
    position: relative;
    --icon-color: #5E82F9;
    box-shadow: 17px 12px 72px rgba(118, 123, 170, 0.12);

  }
  .mobile-navigation-button-back-layer {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(120, 118, 210, 0.07);
    filter: blur(9px);
  }

  .mobile-navigation-button:disabled,
  .mobile-navigation-button[disabled=true]{
    --icon-color: lightgray;
    cursor: default;
    pointer-events: none;
  }

  :deep(path) {
    fill: var(--icon-color);
    transition: 1s linear;
  }
</style>