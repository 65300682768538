<template>
  <div class="floating-review-box" ref="boxRef" :style="{
    opacity: selected ? 0 : 1,
    marginLeft: customStyles.left + 'vw',
    marginTop: customStyles.top + 'vh',
    zIndex: active ? 3 : 2
  }">
    <div class="floating-user-avatar"
         :style="{ width: customStyles.width + 'px', height: customStyles.width + 'px'}"
         @click="() => onClick(index)"
         @mouseenter="$emit('floating-box-enter', index)"
         @mouseleave="$emit('floating-box-leave', index)">
      <div
          class="user-avatar-overlay"
          :style="{ backgroundColor: customStyles.color }" />
      <img :src="item.img"
           class="user-avatar-image"
           :style="{left: customStyles.avatarLeft + '%', top: customStyles.avatarTop + '%'}"/>
    </div>
  </div>
</template>

<script>
import animations from "@/utils/animations";
import {  ref, onBeforeUpdate, onMounted } from "vue"
export default {
  name: "FloatingUserBox",
  props: {
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    onClick: {
      type: Function,
      default: () => {}
    },
    active: {
      type: Boolean,
      default: false
    },
    customStyles: {
      type: Object,
      default: () => {return {top: 0, left:0, width: 0, avatarTop: 0, avatarLeft: 0}}
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const boxRef = ref(null);

    onMounted(() => {
      animations.float(boxRef.value);
    })

    onBeforeUpdate(() => {
      boxRef.value = null;
    });

    return {
      boxRef
    }
  }
}
</script>

<style scoped>
.floating-review-box {
  position: absolute;
  z-index: 2;
  pointer-events: none;
  transition: opacity 0.5s;

}
  .floating-user-avatar {
    opacity: 0.7;
    border-radius: 50%;
    border: 4px solid white;
    cursor: pointer;
    pointer-events: all;
    position: relative;
    transition: all 0.6s;
  }

  .floating-user-avatar:hover {
    opacity: 1;
    transform: scale(1.5);
  }

  .user-avatar-overlay {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
  }

  .user-avatar-image {
    position: absolute;
    width: 82%;
    height: 82%;
    border-radius: 50%;
  }

</style>